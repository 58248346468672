<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="true"
      api-url="/common/v1/zns-message-list"
      api-download-url="/common/v1/zns-message-export"
      count-component="zns-count"
      download-file-name="tin-nhan-zns.xlsx"
    ></custom-table>
  </div>
</template>

<script>
import {
  ZALO_SENT_STATUS_OPTIONS,
  ZALO_DELIVERY_STATUS_OPTIONS,
  GOODS_ISSUE_STATE,
} from "@/libs/const";
export default {
  name: "Index",
  components: {},
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    sentStatusOptions: [...ZALO_SENT_STATUS_OPTIONS],
    deliveryStatusOptions: [...ZALO_DELIVERY_STATUS_OPTIONS],
    orderOnlineStatusOptions: [...GOODS_ISSUE_STATE],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const columns = [
      {
        type: "select-customer",
        label: this.$t("labels.customer"),
        placeholder: this.$t("labels.customer"),
        name: "id_customer",
        hasSort: false,
        sortName: "company_name",
        key: "company_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.zalo_oa"),
        placeholder: this.$t("labels.zalo_oa"),
        name: "customer_zalo_name",
        hasSort: false,
        sortName: "customer_zalo_name",
        key: "customer_zalo_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.template"),
        placeholder: this.$t("labels.template"),
        name: "template_name",
        hasSort: false,
        sortName: "template_name",
        key: "template_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.zalo_tracking_id"),
        placeholder: this.$t("labels.zalo_tracking_id"),
        name: "zalo_tracking_id",
        hasSort: false,
        sortName: "zalo_tracking_id",
        key: "zalo_tracking_id",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone_number"),
        placeholder: this.$t("labels.phone_number"),
        name: "customer_phone",
        hasSort: false,
        sortName: "customer_phone",
        key: "customer_phone",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "customer_name",
        hasSort: false,
        sortName: "customer_name",
        key: "customer_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.order_online"),
        placeholder: this.$t("labels.order_online"),
        name: "order_online_tracking",
        hasSort: false,
        sortName: "order_online_tracking",
        key: "order_online_tracking",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.order_online_status"),
        placeholder: this.$t("labels.order_online_status"),
        name: "order_online_state",
        hasSort: false,
        sortName: "order_online_state",
        key: "order_online_state",
        options: this.orderOnlineStatusOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.order_pos"),
        placeholder: this.$t("labels.order_pos"),
        name: "order_pos_tracking",
        hasSort: false,
        sortName: "order_pos_tracking",
        key: "order_pos_tracking",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "zalo_error_code",
        hasSort: false,
        sortName: "zalo_error_code",
        key: "zalo_status",
        options: this.sentStatusOptions,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.sent_time"),
        placeholder: this.$t("labels.sent_time"),
        name: "sent_time",
        hasSort: false,
        sortName: "sent_time",
        key: "sent_time",
        required: true,
      },
      /* {
        type: 'select-filter',
        label: this.$t('labels.zalo_delivery_status'),
        placeholder: this.$t('labels.zalo_delivery_status'),
        name: 'delivery_status',
        hasSort: false,
        sortName: 'delivery_status',
        key: 'delivery_status_txt',
        options: this.deliveryStatusOptions,
        required: true,
      }, */
      {
        type: "date-range-filter",
        label: this.$t("labels.zalo_delivery_time"),
        placeholder: this.$t("labels.zalo_delivery_time"),
        name: "delivery_time",
        hasSort: false,
        sortName: "delivery_time",
        key: "delivery_time",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
  },
};
</script>

<style scoped></style>
